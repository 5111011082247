<template>
  <div class="col-12 card nocon" id="profile_sidebar">
    <div class="mb-0">
      <div class="card-header">
        <h3 class="card-title">Randevu Takvimi</h3>
      </div>
      <div class="left text-primary p-0" @click="left">
        <i class="fa fa-angle-left"></i>
      </div>
      <div class="right text-primary p-0" @click="right">
        <i class="fa fa-angle-right"></i>
      </div>

      <div
        class="card-body"
        id="test"
        :style="
          'transition: all 1s ease 0s;overflow: hidden;height:' +
          height / bol +
          'px;'
        "
      >
        <template v-if="errorMode">
          <h3 class="text-center w-100" style="margin-top: -15px;font-size: 19px;">
            {{ errorMessage }}
          </h3>
        </template>
        <template v-else>
          <div class="dateList box" id="box" v-if="!loader">
            <div v-for="(item, i) in list.dates" :key="i">
              <div class="topmenu">
                <span>{{ item }}</span
                ><br />
                <small>{{ list.list[i].date }}</small>
              </div>
              <template v-if="list.list[i].list.length == 0">
                <li style="background: #ccc">-</li>
              </template>
              <template v-for="(time, a) in list.list[i].list">
                <template v-if="time.available == 0">
                  <li style="background: #ccc" :key="'hour' + a">
                    <del style="background: #ccc">{{
                      time.begin
                    }}</del>
                  </li></template
                >
                <template v-else>
                  <li
                    style="background: #bb7494"
                    :class="
                      'sList ' +
                      (selectedDate ==
                      list.list[i].date_link + ' ' + time.begin
                        ? 'selected'
                        : '')
                    "
                    :id="'select' + list.list[i].date_link + '-' + a"
                    @click="
                      select(
                        list.list[i].date_link +
                          ' ' +
                          time.begin,
                        a,
                        time.id
                      )
                    "
                    :key="'hour' + a"
                  >
                    <a
                      :href="
                        '/appointment/' +
                        list.list[i].user_info.shortlink +
                        '/' +
                        list.list[i].date_link +
                        ' ' +
                        time.begin
                      "
                      >{{ time.begin }}</a
                    >
                  </li></template
                >
              </template>
            </div>
          </div>
        </template>
        <div class="dateList box row" id="box" v-if="loader">
          <div class="skeleton col-12" style="width: 500px; height: 51px"></div>
          <div
            class="skeleton col-12"
            style="margin-top: 20px; width: 500px; height: 350px"
          ></div>
        </div>
      </div>
      <div @click="loadmore" class="loadmore">
        <i v-if="calenderToggleBool" class="fa fa-angle-down"></i>
        <i v-if="!calenderToggleBool" class="fa fa-angle-up"></i>
      </div>
    </div>
  </div>
</template>
<style scoped>
.activeSelect {
  box-shadow: 0px 0px 20px 0px #bb7494;
  -webkit-transition: 0.7s ease-in;
  -o-transition: 0.7s ease-in;
  transition: 0.7s ease-in;
  position: relative;
  z-index: 5003;
}
.nocon {
  transition-duration: 0.1s;
  transition-timing-function: ease-in;
}
.topmenu {
  padding: 0 !important;
  background: #eaeaea;
  margin-bottom: 10px;
  margin-left: -10px;
  margin-top: -10px;
  height: 52px;
}
.loadmore {
  text-align: center;
  font-size: 20px;
  box-shadow: 0px -16px 15px -14px #000000;
  position: inherit;
}
.loadmore:hover {
  cursor: pointer;
}
.selected {
  background: rgb(114 55 82) !important;
  color: white;
}
.left {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  position: absolute;
  left: -15px;
  top: 60px;
  z-index: 9;
  padding: 11px !important;
  background: white;
  box-shadow: -10px 0px 10px -5px #cfcfcf;
}
.right {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  position: absolute;
  right: -15px;
  top: 60px;
  z-index: 9;
  padding: 11px !important;
  background: white;
  box-shadow: 10px 0px 10px -5px #cfcfcf;
}
.dateList {
  display: inline-flex;
  position: absolute;
  list-style: none;
  left: 0;
  top: 0;
}
.dateList div {
  width: 88px;
  text-align: center;
  padding: 10px;
}
.dateList li {
  padding: 5px;
  margin: 5px;
  color: white;
  border-radius: 10px;
}
.dateList li a {
  color: white;
  cursor: pointer;
}
.dateList li a:hover {
  color: white !important;
  cursor: pointer;
}
</style>
<script>
export default {
  props: ["userid", "selectedDate"],
  created() {
    this.$store.state.header = true;
    this.$store.state.footer = true;
  },
  data() {
    return {
      height: 0,
      bol: 2,
      list: [],
      calenderToggleBool: true,
      loader: true,
      errorMode: false,
      errorMessage: "",
    };
  },
  watch: {
    userid: function () {
      let appointmentsGet = {
        user_id: this.userid,
      };
      this.errorMode = false;
      this.$store.dispatch("getAppointments", appointmentsGet).then((value) => {
        if (value.type == "error") {
          this.errorMode = true;
          if (value.message == "ERRxAPNF") {
            /**RANDEVU SİSTEMİ KAPALI */
            this.errorMessage = "Randevu Sistemi Kapalı";
          }
          if (value.message == "ERRxUNF") {
            /**KULLANICI BULUNAMADI */
            this.errorMessage = "Kullanıcı Bulunamadı";
          }
          this.height = 100;
        } else {
          this.list = value;
          this.loader = false;
          setTimeout(() => {
            this.height = document.getElementById("box").offsetHeight + 10;
          }, 500);
        }
      });
    },
  },
  mounted() {
    let appointmentsGet = {
      user_id: this.userid,
    };
    this.$store.dispatch("getAppointments", appointmentsGet).then((value) => {
      this.list = value;
      this.loader = false;
      setTimeout(() => {
        this.height = document.getElementById("box").offsetHeight + 10;
      }, 500);
    });
  },
  methods: {
    left() {
      document.getElementById("test").scrollBy({
        top: 0,
        left: -100,
        behavior: "smooth",
      });
    },
    right() {
      document.getElementById("test").scrollBy({
        top: 0,
        left: +100,
        behavior: "smooth",
      });
    },
    select(date, i, id) {
      console.log(window.history);
      $(".sList").removeClass("selected");
      document
        .getElementById("select" + date.split(" ")[0] + "-" + i)
        .classList.add("selected");
    },
    loadmore() {
      if (this.bol == 1) {
        this.calenderToggleBool = true;
        this.bol = 2;
      } else {
        this.calenderToggleBool = false;
        this.bol = 1;
      }
    },
  },
};
</script>