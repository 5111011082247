<template>
  <div class="mt-3">
    <i class="fa fa-star star" :id="'star-1-' + id"></i>
    <i class="fa fa-star star" :id="'star-2-' + id"></i>
    <i class="fa fa-star star" :id="'star-3-' + id"></i>
    <i class="fa fa-star star" :id="'star-4-' + id"></i>
    <i class="fa fa-star star" :id="'star-5-' + id"></i>
    <template v-if="comment != null"> Görüş ({{ comment }}) </template>
  </div>
</template>
<style scoped>
.star {
  font-size: 20px;
  color: grey;
}
</style>
<script>
export default {
  props: ["pos", "comment", "id"],
  mounted() {
    this.check();
  },
  data() {
    return {};
  },
  watch: {
    id: function () {
      this.check();
    },
  },
  methods: {
    check() {
      setTimeout(() => {
        if (this.pos == 1) {
          document
            .getElementById("star-1-" + this.id)
            .classList.add("text-yellow");
          document
            .getElementById("star-2-" + this.id)
            .classList.remove("text-yellow");
          document
            .getElementById("star-3-" + this.id)
            .classList.remove("text-yellow");
          document
            .getElementById("star-4-" + this.id)
            .classList.remove("text-yellow");
          document
            .getElementById("star-5-" + this.id)
            .classList.remove("text-yellow");
        }
        if (this.pos == 2) {
          document
            .getElementById("star-1-" + this.id)
            .classList.add("text-yellow");
          document
            .getElementById("star-2-" + this.id)
            .classList.add("text-yellow");
          document
            .getElementById("star-3-" + this.id)
            .classList.remove("text-yellow");
          document
            .getElementById("star-4-" + this.id)
            .classList.remove("text-yellow");
          document
            .getElementById("star-5-" + this.id)
            .classList.remove("text-yellow");
        }
        if (this.pos == 3) {
          document
            .getElementById("star-1-" + this.id)
            .classList.add("text-yellow");
          document
            .getElementById("star-2-" + this.id)
            .classList.add("text-yellow");
          document
            .getElementById("star-3-" + this.id)
            .classList.add("text-yellow");
          document
            .getElementById("star-4-" + this.id)
            .classList.remove("text-yellow");
          document
            .getElementById("star-5-" + this.id)
            .classList.remove("text-yellow");
        }
        if (this.pos == 4) {
          document
            .getElementById("star-1-" + this.id)
            .classList.add("text-yellow");
          document
            .getElementById("star-2-" + this.id)
            .classList.add("text-yellow");
          document
            .getElementById("star-3-" + this.id)
            .classList.add("text-yellow");
          document
            .getElementById("star-4-" + this.id)
            .classList.add("text-yellow");
          document
            .getElementById("star-5-" + this.id)
            .classList.remove("text-yellow");
        }
        if (this.pos == 5) {
          document
            .getElementById("star-1-" + this.id)
            .classList.add("text-yellow");
          document
            .getElementById("star-2-" + this.id)
            .classList.add("text-yellow");
          document
            .getElementById("star-3-" + this.id)
            .classList.add("text-yellow");
          document
            .getElementById("star-4-" + this.id)
            .classList.add("text-yellow");
          document
            .getElementById("star-5-" + this.id)
            .classList.add("text-yellow");
        }
      }, 100);
    },
  },
};
</script>