<template>
  <div
    class="bg-white border-bottom"
    style="
      background: rgb(246 247 251) !important;
      border-bottom: none !important;
    "
  >
    <div class="container">
      <div
        class="page-header"
        style="background: none !important; color: #504e6f; text-align: center"
      >
        <template v-if="titleType && titleType == 'H1'">
          <h1 class="page-title">{{ title }}</h1>
        </template>
        <template v-else>
          <h4 class="page-title">{{ title }}</h4>
        </template>

        <ol class="breadcrumb" v-if="isLoad">
          <li class="breadcrumb-item" v-for="(item, i) in array" :key="i">
            <template v-if="item.islink">
              <router-link :to="item.url" style="color: #504e6f">{{
                item.text
              }}</router-link>
            </template>
            <template v-if="!item.islink">
              {{ item.text }}
            </template>
            <template v-if="item.isCity">
              <select
                @change="locationChange"
                name="city"
                style="border: none; background: none; color: #504e6f"
              >
                <option selected>Şehir Seçin</option>
                <option
                  v-for="(item, i) in cityList"
                  :selected="item.name == city"
                  :value="item.shortname"
                  :key="i"
                >
                  {{ item.name }}
                </option>
              </select>
              <select
                v-if="districtList.length > 0"
                @change="disctrictChange"
                name="district"
                style="border: none; background: none; color: #504e6f"
              >
                <option
                  :selected="item.shortname == district"
                  v-for="(item, i) in districtList"
                  :key="i"
                  :value="item.shortname"
                >
                  {{ item.name }}
                </option>
              </select>
            </template>
          </li>
        </ol>
      </div>
    </div>
  </div>
</template>
<style scoped>
.page-header .breadcrumb-item + .breadcrumb-item::before {
  color: #504e6f !important;
}
@media (max-width: 480px) {
  .page-title {
    display: none;
  }
  .breadcrumb {
    margin: 0 auto;
    width: fit-content;
  }
}
</style>
<script>
export default {
  props: ["title", "titleType", "array", "scity"],
  created() {
    this.cityGet();
  },
  watch:{
    "array":function(){
      this.isLoad=true;
    }
  },
  data() {
    return {
      cityList: [],
      districtList: [],
      city: "",
      district: "",
      sdistrict: "",
      isLoad:false,
    };
  },
  methods: {
    cityGet() {
      if (this.scity) {
        let cityInfo = {};
        this.$store.dispatch("citysGet", cityInfo).then((value) => {
          this.cityList = value.list;
          if (this.$route.params.city) {
            this.city = this.cityList.filter(
              (country) => country.shortname == this.$route.params.city
            )[0].name;
            this.district = this.$route.params.districts;
            this.districtList = this.cityList.filter(
              (country) => country.shortname == this.$route.params.city
            )[0].districts;
          }

          if (this.$route.query.city) {
            this.city = this.cityList.filter(
              (country) => country.shortname == this.$route.query.city
            )[0].name;
            this.district = this.$route.query.district;
            this.districtList = this.cityList.filter(
              (country) => country.shortname == this.$route.query.city
            )[0].districts;
          }

          if (
            this.$route.params.districts &&
            this.$route.params.districts != null &&
            this.$route.params.districts != ""
          ) {
            this.sdistrict = this.districtList.filter(
              (country) => country.shortname == this.district
            )[0].name;
          }
          return true;
        });
      }
    },
    locationChange() {
      let city = document.querySelector("select[name=city]").value;

      if (
        this.$route.params.search &&
        this.$route.params.search != null &&
        this.$route.params.search != ""
      ) {
        if (
          this.$route.name == "Discomfort" ||
          this.$route.name == "DiscomfortCity" ||
          this.$route.name == "DiscomfortCityDistricts"
        ) {
          this.$router.push(
            "/discomfort/" + this.$route.params.search + "/" + city
          );
        } else if (
          this.$route.name == "ExperiencesSearch" ||
          this.$route.name == "ExperiencesCity" ||
          this.$route.name == "ExperiencesCityDistricts"
        ) {
          this.$router.push(
            "/experiences/" + this.$route.params.search + "/" + city
          );
        } else {
          this.$router.push(
            "/search/" + city + "/" + this.$route.params.search
          );
        }
      } else if (
        this.$route.query.q &&
        this.$route.query.q != null &&
        this.$route.query.q != ""
      ) {
        this.$router.push("/search?q=" + this.$route.query.q + "&city=" + city);
      } else {
        this.$router.push("/city/" + city + "/expert");
      }
      if (this.$route.query.city) {
      this.districtList = this.cityList.filter(
        (country) => country.shortname == this.$route.query.city
      )[0].districts;
      }
      if (this.$route.params.city) {
      this.districtList = this.cityList.filter(
        (country) => country.shortname == this.$route.params.city
      )[0].districts;
      }
      
      this.$parent.getList();
      
    },
    disctrictChange() {
      let city = document.querySelector("select[name=city]").value;
      let district = document.querySelector("select[name=district]").value;
      if (
        this.$route.params.search &&
        this.$route.params.search != null &&
        this.$route.params.search != ""
      ) {
        if (
          this.$route.name == "DiscomfortCity" ||
          this.$route.name == "DiscomfortCityDistricts"
        ) {
          this.$router.push(
            "/discomfort/" +
              this.$route.params.search +
              "/" +
              city +
              "/" +
              district
          );
        } else if (
          this.$route.name == "ExperiencesSearch" ||
          this.$route.name == "ExperiencesCity" ||
          this.$route.name == "ExperiencesCityDistricts"
        ) {
          this.$router.push(
            "/experiences/" +
              this.$route.params.search +
              "/" +
              city +
              "/" +
              district
          );
        }  else {
          this.$router.push(
            "/city/" + city + "/" + district + "/" + this.$route.params.search
          );
        }
      }else if (
          this.$route.query.q &&
          this.$route.query.q != null &&
          this.$route.query.q != "" &&
          this.$route.query.city &&
          this.$route.query.city != null &&
          this.$route.query.city != ""
        ) {
          
          this.$router.push(
            "/search?q=" +
              this.$route.query.q +
              "&city=" +
              city +
              "&district=" +
              district
          );
        } else {
        this.$router.push("/city/" + city + "/" + district);
      }
      this.$parent.getList();
    },
  },
};
</script>