<template>
  <div class="mb-lg-0">
    <div>
      <div class="item2-gl">
        <div class="row">
          <template v-if="list == null || (list.length == 0 && !isLoad)">
            <div class="text-center"><h3>Sonuç Bulunamadı</h3></div>
          </template>
          <template v-if="list == null || (list.length == 0 && isLoad)">
            <div class="text-center"><h3>Sonuçlar getiriliyor...</h3></div>
          </template>
          <div
            class="col-lg-4 col-md-12 col-xl-4"
            v-for="(item, i) in list"
            :key="'user' + i"
          >
          
            <div class="card tItem overflow-hidden">
              <div class="item-card9-imgs">
                <span v-if="item.is_pre_meeting==1"  class="badge btn-sm" style="position: absolute; background:#fff; color:rgb(156 73 111);text-align: center; bottom: 0px;border-radius: 5px;     left: 50%; transform: translate(-50%, -50%);     box-shadow: 0px 5px 5px -7px #000;  z-index: 100;">Ücretsiz Ön Görüşme</span>
                <router-link :to="'/terapist/' + item.shortlink"></router-link>
                <lazy-image
                  :src="
                    'https://storage.terapivitrini.com/' +
                    item.profile_image_url
                  "
                  :alt="item.fullname"
                  class="w-100"
                />
              </div>
              <div class="card-body">
                <div class="item-card2">
                  <div class="item-card2-desc text-center">
                    <div class="item-card2-text">
                      <router-link
                        :to="'/terapist/' + item.shortlink"
                        class="text-dark"
                        ><h2
                          class="font-weight-bold mb-1"
                          style="font-size: 20px"
                        >
                          {{ item.fullname }}
                        </h2></router-link
                      >
                      <p class="fs-16 mb-1">{{ item.expert }}</p>
                      <StarPos
                        :pos="Math.ceil(item.total_score / item.total_comment)"
                        :id="item.user_id"
                        :comment="item.total_comment"
                      />
                    </div>
                    <div v-if="item.price && item.price.length>0">
                      <div class="interview-price">Seans {{ item.price[0].price }} TL</div>
                      <div class="interview-item-row">
                        <div class="interview-item" v-if="item.price[0].home_service==1">
                          Evde 
                        </div>
                        <div class="interview-item" v-if="item.price[0].face_to_face==1">
                          Yüz Yüze 
                        </div>
                        <div class="interview-item" v-if="item.price[0].online==1">
                          Online 
                        </div>
                      </div>
                      
                      
                    </div>
                    
                    <div class="btn">
                      <router-link
                        style="margin-right: 3px"
                        :to="'/terapist/' + item.shortlink"
                        class="btn btn-primary btn-sm"
                        >Profil Detay</router-link
                      >
                      <button
                        class="btn btn-warning btn-sm"
                        @click="appointmentDialogOpen(item.user_id)"
                      >
                        Randevu Al
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          
          <div class="modal" tabindex="-1" role="dialog" id="appointmentDialog">
            <div class="modal-dialog" role="document">
              <div class="modal-content" style="background: none; border: none">
                <div class="modal-body">
                  <Sidebar :userid="userId" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style>
.owl-dots {
  margin-top: -20px !important;
  margin-bottom: 10px !important;
}
.mobile{
  width: fit-content;
  float: left;
}
.mobile-left{
  width: 40%;
}
.mobile-center{
  width: 60%;
}
.mobile-image{
  background: #ddd;
  border-radius: 100px;
  width: auto;
}
.interview-price{
  font-weight: bold;
}
.interview-item-row{
  margin: 0 auto;
  width: fit-content;
  margin-top: 3px;
}
.interview-item{
  float: left;
    border-radius: 10px;
    border:1px solid #b2b1b1;
    padding: 0px 4px 0px 4px;
    margin: 3px;
    color: #000;
    font-size: 12px;
}
</style>
<script>
import Sidebar from "../../ProfileDetail/Components/Sidebar.vue";
import StarPos from "../../../components/StarPos.vue";
export default {
  props: ["list", "isLoad"],
  components: {
    Sidebar,
    StarPos,
  },
  data() {
    return {
      userId: null,
    };
  },
  mounted() {
    $("#appointmentDialog").on("show.bs.modal", function (e) {
      window.location.hash = "modal";
    });

    $(window).on("hashchange", function (event) {
      if (window.location.hash != "#modal") {
        $("#appointmentDialog").modal("hide");
      }
    });
  },
  methods: {
    appointmentDialogOpen(id) {
      $("#appointmentDialog").modal("show");
      this.userId = id;
    },
    appointmentDialogClose() {
      $("#appointmentDialog").modal("hide");
    },
  },
};
</script>